import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'
import SEO from "../components/seo"

const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Medical" />
        <div >
            <Image alt="military" filename="medical-banner.png"  />
        </div>
        <Container>
            <Row className="servicesPage">
                <h2 className="servicePageHead">Medical Device Design Services</h2>
            </Row>
            <hr className="embhr" />
            <Row>
                <Col sm={12} lg={6}>
                    <Image alt="military" filename="medical-inside.png"  />
                </Col>
                <Col sm={12} lg={6} className="servicePagePara">
                For today’s advanced solutions to healthcare and medical innovation, Trace Width Technology Solutions provides modern solutions for the medical industry. The use and research of medical innovation and technology is booming and we are your go to partner for commanding your share of this ever competitive market.
                </Col>
                
                
               
            </Row>
            </Container>
            
        <div className="headerForm"> 
            <br/>
            
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

